import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export default i18n.use(initReactI18next).init({
  lng: window?.localStorage?.getItem?.('@odex-user-lng') || 'US',
  debug: false,
  fallbackLng: 'US',
  resources: {
    US: {
      resource_1: require('./resources/US/resource-1.json'),
      resource_2: require('./resources/US/resource-2.json'),
      resource_3: require('./resources/US/resource-3.json'),
      resource_4: require('./resources/US/resource-4.json'),
      resource_5: require('./resources/US/resource-5.json'),
      resource_7: require('./resources/US/resource-7.json'),
      resource_12: require('./resources/US/resource-12.json'),
      resource_message: require('./resources/US/resource-message.json'),
    },
    SG: {
      resource_1: require('./resources/SG/resource-1.json'),
      resource_2: require('./resources/SG/resource-2.json'),
      resource_3: require('./resources/SG/resource-3.json'),
      resource_4: require('./resources/SG/resource-4.json'),
      resource_5: require('./resources/SG/resource-5.json'),
      resource_7: require('./resources/SG/resource-7.json'),
      resource_12: require('./resources/SG/resource-12.json'),
      resource_message: require('./resources/SG/resource-message.json'),
    },
    ZA: {
      resource_1: require('./resources/ZA/resource-1.json'),
      resource_2: require('./resources/ZA/resource-2.json'),
      resource_3: require('./resources/ZA/resource-3.json'),
      resource_4: require('./resources/ZA/resource-4.json'),
      resource_5: require('./resources/ZA/resource-5.json'),
      resource_7: require('./resources/ZA/resource-7.json'),
      resource_12: require('./resources/ZA/resource-12.json'),
      resource_message: require('./resources/ZA/resource-message.json'),
    },
    MY: {
      resource_1: require('./resources/MY/resource-1.json'),
      resource_2: require('./resources/MY/resource-2.json'),
      resource_3: require('./resources/MY/resource-3.json'),
      resource_4: require('./resources/MY/resource-4.json'),
      resource_5: require('./resources/MY/resource-5.json'),
      resource_7: require('./resources/MY/resource-7.json'),
      resource_12: require('./resources/MY/resource-12.json'),
      resource_message: require('./resources/MY/resource-message.json'),
    },
    KE: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    JO: {
      resource_1: require('./resources/JO/resource-1.json'),
      resource_2: require('./resources/JO/resource-2.json'),
      resource_3: require('./resources/JO/resource-3.json'),
      resource_4: require('./resources/JO/resource-4.json'),
      resource_5: require('./resources/JO/resource-5.json'),
      resource_7: require('./resources/JO/resource-7.json'),
      resource_12: require('./resources/JO/resource-12.json'),
      resource_message: require('./resources/JO/resource-message.json'),
    },
    UG: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    RW: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
     BI: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    CD: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    SS: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    TZ: {
      resource_1: require('./resources/KE/resource-1.json'),
      resource_2: require('./resources/KE/resource-2.json'),
      resource_3: require('./resources/KE/resource-3.json'),
      resource_4: require('./resources/KE/resource-4.json'),
      resource_5: require('./resources/KE/resource-5.json'),
      resource_7: require('./resources/KE/resource-7.json'),
      resource_12: require('./resources/KE/resource-12.json'),
      resource_message: require('./resources/KE/resource-message.json'),
    },
    PK: {
      resource_1: require('./resources/PK/resource-1.json'),
      resource_2: require('./resources/PK/resource-2.json'),
      resource_3: require('./resources/PK/resource-3.json'),
      resource_4: require('./resources/PK/resource-4.json'),
      resource_5: require('./resources/PK/resource-5.json'),
      resource_7: require('./resources/PK/resource-7.json'),
      resource_12: require('./resources/PK/resource-12.json'),
      resource_message: require('./resources/PK/resource-message.json'),
    }
  },
});
